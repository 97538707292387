import { navigate } from 'gatsby'
import { useEffect } from 'react'

const notFound = () => {
  useEffect(() => {
    navigate('/en/404')
  }, [])
  return null
}

export default notFound; 